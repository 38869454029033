@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Utils */

.p-default {
  @apply px-[20px] md:px-8 lg:px-16;
}

.fullheight {
  height: calc(100vh - 96px);
}

/* Text styles */

h1 {
  @apply leading-tight text-[22px] md:text-[42px] lg:text-6xl font-bold text-th-dark uppercase;
}

h2 {
  @apply text-lg md:text-[22px] lg:text-[26px] leading-[110%] font-semibold text-th-dark uppercase;
}

h3 {
  @apply leading-tight text-[22px] md:text-[42px] uppercase lg:text-6xl font-bold text-th-dark;
}

p {
  @apply text-base md:text-lg xl:text-2xl leading-snug font-normal text-th-dark;
}

.btn-primary {
  @apply bg-th-red rounded-full text-lg uppercase text-white font-semibold text-center h-14 px-8;
}

/* Components */

.main-nav a {
  @apply text-lg lg:text-[22px] text-th-grey;
}

.main-nav a:hover {
  @apply text-black;
}

.btn-lang-active {
  @apply bg-th-red border border-th-red rounded-full w-11 h-7 text-center text-white text-base;
}

.btn-lang-inactive {
  @apply bg-white border border-th-grey rounded-full w-11 h-7 text-center text-th-grey text-base;
}

#serv-list li::before {
  content: "- ";
}

.popup-modal-overlay {
  /*background: rgba(209, 24, 13, 0.8);*/
  background: rgba(0, 0, 0, 0.5);
  @apply px-4 max-w-[100vw];
}

.userform input {
  @apply bg-th-dark border-b border-b-th-grey py-4 text-base text-white;
}

.userform input:focus {
  @apply border-b border-b-white bg-th-dark outline-none;
}

.userform input:active {
  @apply bg-th-dark outline-none;
}

.userform input::placeholder {
  @apply text-th-grey text-base;
}

.navlink {
  @apply text-[22px] text-white font-normal;
}

.grid-container {
  grid-template-columns: repeat(13, minmax(0, 1fr));
}

@media (min-width: 1280px) {
  .benefits-container {
    grid-template-columns: repeat(13, minmax(0, 1fr));
  }
}

.error-span {
  @apply text-base md:text-lg  font-normal text-th-red mt-2;
}

.success-span {
  @apply text-base md:text-lg  font-normal text-green-600 mt-2;
}
